/** @jsxImportSource react */
import clsxm from '@common/lib/clsxm';
import { eventNames } from '@react/services/events';
import { Link } from '@react/system';
import {
  Facebook,
  Instagram,
  Linkedin,
  Seniorly,
  X,
} from '@sly/icons/react/index';

const currentYear = new Date().getFullYear();

export const company = {
  'About us': '/company/about',
  'Contact us': '/company/contact-us',
  Careers: '/career',
  Press: '/company/press',
  'How We Make Money': '/company/how-we-make-money',
  'Our Pricing Estimates': '/company/seniorly-estimated-pricing',
  'Review Guidelines': '/company/review-guidelines',
  'Editorial Guidelines': '/company/editorial-guidelines',
};

const typesOf = {
  'Assisted living': '/assisted-living',
  'Independent living': '/independent-living',
  'Board and care homes': '/board-and-care-home',
  'Memory care': '/memory-care',
  'Home care': '/in-home-care',
  'Respite care': '/respite-care',
  'Continuing care retirement communities (CCRC)':
    '/continuing-care-retirement-community',
  'Nursing homes': '/skilled-nursing-facility',
  'Senior living overview': '/senior-living',
};

const forFamilies = {
  'Senior living resources': '/resource-center',
  'Seniorly pricing trends': '/senior-living-pricing-trends',
  'How it works': '/how-it-works',
  'Our Provider Network': '/providers',
  'Seniorly Local Advisors': '/agents',
  "Veteran's benefits": '/veterans-benefit-assisted-living',
  'Activities of daily living (ADLs)':
    '/what-are-activities-of-daily-living-adls',
};

const forPartners = {
  'Partner agents': '/partners/agents',
  'Partner communities': '/partners/communities',
};

export interface FooterLinks {
  items:
    | typeof company
    | typeof typesOf
    | typeof forFamilies
    | typeof forPartners;
}

function Links({ items }: FooterLinks) {
  return (
    <div
      className='flex flex-col pb-s md:flex-row md:flex-wrap lg:flex-col'
      data-tp-id='gen-div-4c269ccf-5e4b-4858-9b2f-f13779bb8c13'
    >
      {Object.entries(items).map(([name, url]) => (
        <Link
          className=' mb-s pr-m !text-sm !text-slate-base hover:text-viridian-base hover:underline md:w-[calc(100%/3)] lg:w-full'
          key={`${name} ${url}`}
          href={url}
          eventProps={{
            location: 'footer',
          }}
          data-tp-id='gen-Link-df8ef49e-6527-471b-b77c-120eb354e7c2'
        >
          {name}
        </Link>
      ))}
    </div>
  );
}

function Footer({ className }: { className?: string }) {
  return (
    <footer className={clsxm(className, 'bg-harvest-lighter-90')}>
      <div
        className='my-0 mx-auto w-full p-l lg:mb-l lg:flex lg:w-[64.5rem] lg:px-0 lg:pb-0'
        data-tp-id='gen-div-861187c4-0bfa-4c8a-9a26-abb7db3d0608'
      >
        <div
          className='flex-1 border-b border-slate-lighter-90 pt-l'
          data-tp-id='gen-div-b54b2a8a-788e-4549-8060-1e844a7592cc'
        >
          <h2
            className='font-t-xxs-azo mb-l uppercase text-slate-lighter-40'
            data-tp-id='gen-h2-d7420278-cc6f-46ba-9738-6a02b263fe73'
          >
            Company
          </h2>
          <Links items={company} />
        </div>
        <div
          className='flex-1 border-b border-slate-lighter-90 pt-l'
          data-tp-id='gen-div-7a50a090-8e83-4fd3-9244-a0512f912d3b'
        >
          <h2
            className='font-t-xxs-azo mb-l uppercase text-slate-lighter-40'
            data-tp-id='gen-h2-cec4590a-1f92-4f16-b0c4-32bc7b904387'
          >
            Types of senior living
          </h2>
          <Links items={typesOf} />
        </div>
        <div
          className='flex-1 border-b border-slate-lighter-90 pt-l'
          data-tp-id='gen-div-89c6349e-1bf4-4ad4-be0d-1277ae55c509'
        >
          <h2
            className='font-t-xxs-azo mb-l uppercase text-slate-lighter-40'
            data-tp-id='gen-h2-07e915e1-e6f6-42b6-b525-3fbd705ac0b7'
          >
            For families
          </h2>
          <Links items={forFamilies} />
        </div>
        <div
          className='flex-1 border-b border-slate-lighter-90 pt-l'
          data-tp-id='gen-div-86b7902a-07ba-4c3e-a2bd-76fc114c667c'
        >
          <h2
            className='font-t-xxs-azo mb-l uppercase text-slate-lighter-40'
            data-tp-id='gen-h2-753aa2da-6d17-45c6-aba6-133ab193df0c'
          >
            For partners
          </h2>
          <Links items={forPartners} />
        </div>
      </div>

      <div
        className='relative my-0 mx-auto flex px-l pb-l lg:w-[64.5rem]'
        data-tp-id='gen-div-d1a6d0dc-b530-4116-8bed-6c60e46e6981'
      >
        <div
          className='flex flex-col items-center gap-m md:flex-row'
          data-tp-id='gen-div-a16e3628-0f1b-4cd9-ac2d-f2b0705e5488'
        >
          <Link
            to='/'
            event='Button Clicked'
            className='self-start'
            eventProps={{ text: 'logoMark', href: '/', location: 'footer' }}
            data-tp-id='gen-Link-36f57a4b-4f7f-436e-9ccf-01bc9634b259'
          >
            <Seniorly className='mb-l -mt-1 md:mb-0' size='l' />
          </Link>
          <span
            className='font-b-s'
            data-tp-id='gen-span-31f22b4d-68e4-424e-875d-1e54e426bdfd'
          >
            &copy; Seniorly {currentYear} ·{' '}
            <Link
              eventProps={{
                location: 'footer',
              }}
              to='/privacy'
              data-tp-id='gen-Link-0f405dfd-b621-48ca-afd7-e4a05981be62'
            >
              Privacy
            </Link>{' '}
            ·{' '}
            <Link
              eventProps={{
                location: 'footer',
              }}
              to='/tos'
              data-tp-id='gen-Link-d0a8d3b3-7394-4d69-b786-3f5ac557e189'
            >
              Terms
            </Link>{' '}
            ·{' '}
            <Link
              eventProps={{
                location: 'footer',
              }}
              href='/sitemap'
              data-tp-id='gen-Link-41b40a77-7a3d-4d41-acff-b545278be9b2'
            >
              Sitemap
            </Link>
          </span>
        </div>
        <div
          className='absolute top-0 right-6'
          data-tp-id='gen-div-b0a1cd9b-9667-4b4f-a93f-12ef7a933431'
        >
          <Link
            className='ml-s text-slate-base'
            event={eventNames.ButtonClicked}
            eventProps={{
              icon: 'facebook',
              location: 'footer',
              text: 'facebook',
            }}
            href='https://www.facebook.com/seniorly/posts'
            data-tp-id='gen-Link-6e16743c-42c7-4dd1-a9f6-f39a89042d93'
          >
            <Facebook />
          </Link>
          <Link
            className='ml-s text-slate-base'
            event={eventNames.ButtonClicked}
            eventProps={{
              icon: 'instagram',
              location: 'footer',
              text: 'instagram',
            }}
            href='https://www.instagram.com/seniorlyinc'
            data-tp-id='gen-Link-1bd6d9d6-fb2c-4807-86a6-a908a7b61145'
          >
            <Instagram />
          </Link>
          <Link
            className='ml-s text-slate-base'
            event={eventNames.ButtonClicked}
            eventProps={{
              icon: 'twitter',
              location: 'footer',
              text: 'twitter',
            }}
            href='https://twitter.com/Seniorly'
            data-tp-id='gen-Link-8b6ae588-b65e-4a30-a6bd-69228477221f'
          >
            <X />
          </Link>
          <Link
            className='ml-s text-slate-base'
            event={eventNames.ButtonClicked}
            eventProps={{
              icon: 'linkedin',
              location: 'footer',
              text: 'linkedin',
            }}
            href='https://www.linkedin.com/company/seniorly'
            data-tp-id='gen-Link-c806b911-135d-45ae-9fe4-8a1d290b81e9'
          >
            <Linkedin />
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
